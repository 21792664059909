<template>
	<div class="HelpCenter">
		<w-navTab titleText="关于我们"></w-navTab>
		<div class="container">
			<div class="HelpCenter-top">
				<img src="../../assets/img/test203.png" alt="">
			</div>
			<div class="HelpCenter-title">上饶义警平台</div>
			<div class="HelpCenter-text">版本：V 3.5</div>
			<div class="HelpCenter-body">
				<div class="HelpCenter-body-text">
					一座城，一条心，一起拼，一定赢
				</div>
				<div class="HelpCenter-body-text">
					打造新时代义警铁军，构筑平安建设新防线
				</div>
			</div>
			<div class="HelpCenter-bottom">
				<div class="HelpCenter-bottom-text">上饶市平安义警协会</div>
				<div class="HelpCenter-bottom-text">江西万网科技有限公司 技术支持</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HelpCenter',
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style lang="less" scoped>
	.HelpCenter {
		width: 100%;
		height: 100vh;
		background-color: #fff;

		.container {
			padding-top: 20px;
			box-sizing: border-box;

			.HelpCenter-top {
				width: 90px;
				height: 90px;
				margin: 0 auto;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.HelpCenter-title {
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				margin-top: 15px;
				margin-bottom: 10px;
			}

			.HelpCenter-text {
				text-align: center;
				font-size: 16px;
				color: #666;
			}

			.HelpCenter-body {
				text-align: center;
				margin-top: 20px;

				.HelpCenter-body-text {
					font-size: 16px;
					margin-bottom: 10px;
					color: #666;
				}
			}

			.HelpCenter-bottom {
				width: 100%;
				position: fixed;
				left: 50%;
				transform: translateX(-50%);
				bottom: 20px;
				text-align: center;

				.HelpCenter-bottom-text {
					font-size: 14px;
					color: #666;
					margin-bottom: 3px;
				}
			}
		}
	}
</style>
